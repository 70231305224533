import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import AppProvider from './provider/AppProvider'
import Main from './app/Main'



class App extends Component {
  render() {
    return (
      <AppProvider>
           <Main/>
      </AppProvider>
    )
  }
}

export default App
