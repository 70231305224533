import React, { useEffect, useState } from 'react'
import { AppContext } from 'src/context/AppContext'
import axios from 'axios'
import { base_url } from '../app/config/base_url'
import { useNavigate } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import Swal from 'sweetalert2'

const AppProvider = ({ children }) => {
  const history = createBrowserHistory() // experimental
  const [user, setUser] = useState({}) // userDetails
  const [islogged, setLoggedIn] = useState(false) // user login State

  const LOGIN_STATE = '1'
  const LOGOUT_STATE = '2'
  const [TIMEOUT, SETTIMEOUT] = useState(600000)

  const [userLastDayLog, setUserLastDayLog] = useState('')

  //get setting
  const getSetting = () => {
    fetch('manifest.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(function (response) {
        // console.log(response)
        return response.json()
      })
      .then(function (myJson) {
        if (
          myJson.logoutTimeout != null ||
          myJson.logoutTimeout != '' ||
          myJson.logoutTimeout > 10000
        ) {
          console.log('all SetUp')
          SETTIMEOUT(myJson.logoutTimeout)
        }
      })
  }

  //Admin Access Only
  const [empList, setEmpList] = useState([])
  const [empChecks, setEmpCheck] = useState([])
  const [empChecksALL, setEmpCheckALL] = useState([])

  //Checking User active or not
  const checkForInactivity = () => {
    const expireTime = localStorage.getItem('expireTime')
    const state = localStorage.getItem('state')
    if (expireTime != null && state != null && expireTime < Date.now()) {
      console.log('I AM ')
      signOut()
    }
  }

  //Control the User active limit
  const updateExpireTime = () => {
    const expireTime = Date.now() + TIMEOUT
    localStorage.setItem('expireTime', expireTime)
  }

  //Important
  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity()
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  //Important
  useEffect(() => {
    updateExpireTime()

    window.addEventListener('click', updateExpireTime)
    window.addEventListener('keypress', updateExpireTime)
    window.addEventListener('scroll', updateExpireTime)
    window.addEventListener('mousemove', updateExpireTime)

    return () => {
      window.addEventListener('click', updateExpireTime)
      window.addEventListener('keypress', updateExpireTime)
      window.addEventListener('scroll', updateExpireTime)
      window.addEventListener('mousemove', updateExpireTime)
    }
  }, [])

  //Hours Coverter
  const convertSeconds = (seconds) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)

    return `${hours} hours : ${minutes} minutes`
  }

  //Get Date in String
  const DateString = (add = 0) => {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    dd = parseInt(dd) + add
    dd = String(dd)
    today = yyyy + '-' + mm + '-' + dd
    return today
  }

  //Login Auth Function
  const signIn = async (email, password) => {
    const params = {
      username: email,
      password: password,
    }
    await axios
      .post(`${base_url}auth/login-user`, params)
      .then((res) => {
        console.log(res)
        console.log(params)
        if (res.data.data != null) {
          const userData = res.data.data
          localStorage.setItem('User', JSON.stringify(userData))
          localStorage.setItem('state', islogged)
          setLoggedIn(true)
          updateExpireTime()
          updateActivity(userData.user_id, LOGIN_STATE)
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Login Sucessfully',
            showConfirmButton: false,
            timer: 1500,
          })
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //LogOut Auth Function
  const signOut = () => {
    const userInfo = JSON.parse(localStorage.getItem('User')) || ''
    if (userInfo != '') {
      updateActivity(userInfo.user_id, LOGOUT_STATE, () => {
        console.log('LogOut')
        setLoggedIn(false)
        localStorage.clear()
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Logout Sucessfully',
          showConfirmButton: false,
          timer: 1500,
        })
      })
    }
  }

  //OnPage Load Check User Login or not
  const islogin = () => {
    const userInfo = JSON.parse(localStorage.getItem('User')) || ''
    if (userInfo != '') {
      setLoggedIn(true)
      setUser(userInfo)
    }
  }

  //User Activity Update in Database
  const updateActivity = async (user_id, state, callback = () => {}) => {
    await axios
      .post(`${base_url}auth/setlog_activity`, {
        user_id: user_id,
        state: state,
        time: new Date().toLocaleDateString(),
      })
      .then((res) => {
        console.log(res.data.message)
        callback()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //User Details Get Function
  const GetuserDetails = async () => {
    const userInfo = JSON.parse(localStorage.getItem('User'))
    if (userInfo != '') {
      await axios
        .post(`${base_url}user/user_details`, {
          user_id: userInfo.user_id,
        })
        .then((res) => {
          if (res.data.data != null) {
            const info = res.data.data
            let lastLog = info.log[0].log
            lastLog = convertSeconds(lastLog)
            setUserLastDayLog(lastLog)
            setUser(info.userDetails)
          }
        })
        .catch((err) => {
          Swal.fire({
            position: 'center',
            icon: 'info',
            title: 'Something gone Wrong?? Data Getting Error',
          })
        })
    }
  }

  //Important
  useEffect(() => {
    getSetting()
    islogin()
  }, [])

  //Add New Employeee API
  const addEmployeeAPI = async (values) => {
    await axios
      .post(`${base_url}auth/add-emp`, values)
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Get Employee List
  const getEmployeeList = async () => {
    await axios
      .get(`${base_url}employee/employee_list`)
      .then((res) => {
        setEmpList(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Delete Employee
  const deleteEmployee = async (id, callback = () => {}) => {
    await axios
      .post(`${base_url}employee/employee_delete`, { id: id })
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
        })
        callback()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Update Employee
  const updateEmployee = async (values, callback) => {
    await axios
      .post(`${base_url}employee/employee_update`, values)
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
        })
        callback()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Controll CheckIn
  const controlCheckIN = async (values, callback) => {
    await axios
      .post(`${base_url}employee/employee_checkIn`, values)
      .then((res) => {
        console.log(res)
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
        })
        callback()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Employee CheckList
  const getEmployeeCheckList = async (id, callback = (res) => {}) => {
    await axios
      .post(`${base_url}employee/employee_checklist`, { id: id })
      .then((res) => {
        setEmpCheck(res.data.data)
        callback(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //All Employee CheckList
  const getAllEmployeeCheckList = async () => {
    await axios
      .get(`${base_url}employee/employee_check`)
      .then((res) => {
        setEmpCheckALL(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Add Machine
  const addMachine = async (values, callback = () => {}) => {
    await axios
      .post(`${base_url}machine/addmachine`, values)
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
        })
        callback()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Machine List
  const [machineList, setMachineList] = useState([])
  const getOnlyMachine = async (callback=(data)=>{}) => {
    await axios
      .get(`${base_url}machine/getmachine`)
      .then((res) => {
        setMachineList(res.data.data)
        callback(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Machine Update
  const updateMachine = async (values, callback = () => {}) => {
    await axios
      .post(`${base_url}machine/updatemachine`, values)
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
        })
        callback()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Machine Delete
  const deleteMachine = async (id, callback = () => {}) => {
    await axios
      .post(`${base_url}machine/deletemachine`, { machine_id: id })
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
        })
        callback()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Add Project
  const addProject = async (values, callback = () => {}) => {
    await axios
      .post(`${base_url}project/addproject`, values)
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
        })
        callback()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Project List
  const [projectList, setProjectList] = useState([])
  const getOnlyProject = async () => {
    await axios
      .get(`${base_url}project/getproject`)
      .then((res) => {
        setProjectList(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Project Update
  const updateProject = async (values, callback = () => {}) => {
    await axios
      .post(`${base_url}project/updateproject`, values)
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
        })
        callback()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Project Delete
  const deleteProject = async (id, callback = () => {}) => {
    await axios
      .post(`${base_url}project/deleteproject`, { project_id: id })
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
        })
        callback()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Add Daily Machine Status
  const addDailyMachineStatus = async (values, mlist, plist, userID,techList, callback = () => {}) => {
    await axios
      .post(`${base_url}mdaily/addmdaily`, {
        user_id: userID,
        date: values.date,
        project_id: values.location,
        no_of_tech: techList.length,
        no_of_shift: values.no_of_shift,
        machine_list: mlist,
        prev_machine_list: plist.filter(
          (item) =>
            mlist.filter((i) => i.mdetail.machine_id == item.mdetail.machine_id).length <= 0,
        ),
        emp_id:values.emp_id,
        tech_list:techList
      })
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
        })
        callback()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Get Machine Log Status
  const [mlog, setMlog] = useState([])
  const getmachinelog = async (machine_id) => {
    axios
      .post(`${base_url}mdaily/getmachinelog`, { machine_id: machine_id })
      .then((res) => {
        setMlog(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Add New Company for CO2
  const addCO2Company = async (values, callback = () => {}) => {
    await axios
      .post(`${base_url}company/addcompany`, values)
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
        })
        callback()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Get Company for CO2
  const [companyList, setCompanyList] = useState([])
  const getCompany = async (callback = (data) => {}) => {
    await axios
      .get(`${base_url}company/getcompany`)
      .then((res) => {
        setCompanyList(res.data.data)
        callback(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Update Company Details
  const updateCompanyDetails = async (values, callback = () => {}) => {
    await axios
      .post(`${base_url}company/updatecompany`, values)
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
        })
        callback()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Delete Company
  const deleteCompany = async (id, callback = () => {}) => {
    await axios
      .post(`${base_url}company/deletecompany`, { company_id: id })
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
        })
        callback()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Assign CO2
  const assignCO2 = async (values, callback = () => {}) => {
    await axios
      .post(`${base_url}co2/assign`, values)
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
        })
        callback()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Add Vehicle
  const addVehicle = async (formData, callback = (e) => {}) => {
    await axios
      .post(`${base_url}vehicle/addVehicle`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        transformRequest: (formData) => formData,
      })
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
          allowOutsideClick: false,
        })
        callback(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Get All Vehicle
  const [vehicleList, setVehicleList] = useState([])
  const getVehicle = async () => {
    await axios
      .get(`${base_url}vehicle/getVechicle`)
      .then((res) => {
        setVehicleList(res.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  //Delete Vehicle
  const deleteVehicle = async (id, callback = () => {}) => {
    await axios
      .post(`${base_url}vehicle/deleteVehicle`, { v_id: id })
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
          allowOutsideClick: false,
        })
        callback(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Update Vehicle
  const updateVehicle = async (formData, callback = (e) => {}) => {
    await axios
      .post(`${base_url}vehicle/updateVehicle`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        transformRequest: (formData) => formData,
      })
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
          allowOutsideClick: false,
        })
        callback(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Notification
  const [notificationList, setNotification] = useState([])
  const getNotification = async () => {
    await axios
      .get(`${base_url}vehicle/notification`)
      .then((res) => {
        setNotification(res.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  //Add Technician
  const addTechnicianAPI = async (values) => {
    await axios
      .post(`${base_url}tech/addTech`, values)
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Get All Technician
  const [techList,setTechList]=useState([]);
  const getTechnicianList = async (callback=(data)=>{}) => {
    await axios
      .get(`${base_url}tech/getTech`)
      .then((res) => {
        setTechList(res.data.data)
        callback(res.data.data);
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Update Technican 
  const updateTechnician=async(values,callback=(res)=>{})=>{
    await axios
      .post(`${base_url}tech/updateTech`, values)
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
          allowOutsideClick: false,
        })
        callback(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Delete Technican
  const deleteTechnican = async (id, callback = () => {}) => {
    await axios
      .post(`${base_url}tech/deleteTech`, { tech_id: id })
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: res.data.message,
          allowOutsideClick: false,
        })
        callback(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Add New GatePass
  const addGatepass = async (values,callback=()=>{})=>{
    await axios.post(`${base_url}gate/add`,values).
    then((res)=>{
      // Swal.fire({
      //   position: 'center',
      //   icon: 'info',
      //   title: res.data.message,
      // })
      callback();
    }).catch((err)=>{
      console.log(err)
    })
  }

  //get Gatepass
  const [gatepassList,setGatePassList]=useState([]);
  const getAllGatePass= async(callback=(v)=>{})=>{
    await axios.get(`${base_url}gate/list`)
    .then((res)=>{
      setGatePassList(res.data.data);
      callback(res.data.data);
    }).catch((err)=>{
      console.log(err);
    })
  }

  //Delete GatePass 
  const deleteGatePass=async(id,callback=()=>{})=>{
    await axios.post(`${base_url}gate/delete`,{gate_id:id}).
    then((res)=>{
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: res.data.message,
      })
      callback();
    }).catch((err)=>{
      console.log(err)
    })
  }

   //Get Employee Log Status
   const [emplog, setEmplog] = useState([])
   const getemployeelog = async (values) => {
     axios
       .post(`${base_url}mdaily/getemployeelog`, values)
       .then((res) => {
        setEmplog(res.data.data)
       })
       .catch((err) => {
         console.log(err)
       })
   }

   //Get Project logs
   const [projectLog,setProjectLog]=useState({
    machines:[],
    tech:[],
    super:[]
   });
   const getProjectLog=async(id)=>{
    axios
       .post(`${base_url}mdaily/getprojectlog`, {project_id:id})
       .then((res) => {
        setProjectLog(res.data.data)
       })
       .catch((err) => {
         console.log(err)
       })
   }

   //Get CO2 logs
   const [co2Log,setCo2Log]=useState([]);
   const getCo2Log=async(id)=>{
    axios
       .post(`${base_url}mdaily/getCo2log`, {com_id:id})
       .then((res) => {
        setCo2Log(res.data.data)
       })
       .catch((err) => {
         console.log(err)
       })
   }


   //Transfer Machine API
   const TRANSFER_MACHINE_API=async(values,callback=()=>{})=>{
    axios
       .post(`${base_url}transfer/machine`,values)
       .then((res) => {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: res.data.message,
      })
      callback();
       })
       .catch((err) => {
         console.log(err)
       })
   }
   //Transfer Technician API
   const TRANSFER_TECH_API=async(values,callback=()=>{})=>{
    axios
       .post(`${base_url}transfer/tech`,values)
       .then((res) => {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: res.data.message,
      })
      callback();
       })
       .catch((err) => {
         console.log(err)
       })
   }
 //Transfer Employee API
 const TRANSFER_EMP_API=async(values,callback=()=>{})=>{
  axios
     .post(`${base_url}transfer/employee`,values)
     .then((res) => {
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
     })
     .catch((err) => {
       console.log(err)
     })
 }

 //CO2 Setting Getter
 const [CO2Setting,setCO2Setting]=useState({});
 const CO2SETTING_API=async(callback=(res)=>{})=>{
  axios.post(`${base_url}co2/setting`)
  .then((res)=>{
    const value=res.data.data;
    if(value != null){
    setCO2Setting(value);
    callback(value);
    }else{
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: res.data.message,
      });
    }
  })
  .catch((err)=>{
    console.log(err)
  })
 }

 // CO2 Stock Add API
 const CO2STOCK_ADD_API=async(quantity,callback=()=>{})=>{
     axios
     .post(`${base_url}co2/addStock`,{"quantity":quantity})
     .then((res) => {
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
     })
     .catch((err) => {
       console.log(err)
     })
 }

 //CO2 Stock Remove API
 const CO2STOCK_REMOVE_API=async(quantity,callback=()=>{})=>{
  axios
  .post(`${base_url}co2/removeStock`,{"quantity":quantity})
  .then((res) => {
 Swal.fire({
   position: 'center',
   icon: 'info',
   title: res.data.message,
 })
 callback();
  })
  .catch((err) => {
    console.log(err)
  })
}

 // CO2 Stock Add API
 const CO2EMPTY_ADD_API=async(quantity,callback=()=>{})=>{
  axios
  .post(`${base_url}co2/addEmpty`,{"quantity":quantity})
  .then((res) => {
 Swal.fire({
   position: 'center',
   icon: 'info',
   title: res.data.message,
 })
 callback();
  })
  .catch((err) => {
    console.log(err)
  })
}

//CO2 Stock Remove API
const CO2EMPTY_REMOVE_API=async(quantity,callback=()=>{})=>{
axios
.post(`${base_url}co2/removeEmpty`,{"quantity":quantity})
.then((res) => {
Swal.fire({
position: 'center',
icon: 'info',
title: res.data.message,
})
callback();
})
.catch((err) => {
 console.log(err)
})
}


//Provider Add API
const PROVIDER_ADD_API=async(values)=>{
  axios.post(`${base_url}section/addprovider`,values)
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
  }).catch((err)=>{
    console.log(err);
  })
}

//Provider List
const [providerList,setProviderList]=useState([]);
const PROVIDER_GET_API=async(callback=()=>{})=>{
  axios.get(`${base_url}section/providerlist`)
  .then((res)=>{
    setProviderList(res.data.data);
    callback(res.data.data);
  })
  .catch((err)=>{
    console.log(err);
  })
}


const PROVIDER_TANK_ADD=async(values,callback=()=>{})=>{
  await axios.post(`${base_url}section/providertankadd`,values)
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })  
    callback();
  })
  .catch((err)=>{
    console.log(err);
  })
}

const [providerStatus,setProviderStatus]=useState([]);
const PROVIDER_STATUS_API=async()=>{
    await axios.get(`${base_url}section/providerstatuslist`)
    .then((res)=>{
      setProviderStatus(res.data.data);
    })
    .catch((err)=>{
      console.log(err);
    })
}

const PROVIDER_STATUS_DELETE=async(id,callback=()=>{})=>{
 await axios.post(`${base_url}section/deleteproviderstatus`,{id:id})
 .then((res)=>{
  Swal.fire({
    position: 'center',
    icon: 'info',
    title: res.data.message,
  })
  callback();
 })
 .catch((err)=>{
  console.log(err);
 })
}


const COMPANY_ADD_TANK=async(values,callback=()=>{})=>{
  await axios.post(`${base_url}section/companyaddtank`,values)
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
  })
  .catch((err)=>{
    console.log(err);
  })
}


const [companyTankStatus,setCompanyTankStatus]=useState([]);
const COMPANY_TANK_STATUS=async(company_id='',provider_id='')=>{
  await axios.post(`${base_url}section/companytank`,{
    company_id:company_id,
    provider_id:provider_id
  })
  .then((res)=>{
    setCompanyTankStatus(res.data.data);
  })
  .catch((err)=>{
    console.log(err);
  })
}


const [companyXRUN,setCompanyXRUN]=useState(0);
const [companyXRUNOBJ,setCompanyXRUNOBJ]=useState([]);
const COMPANY_XRUN_API=async(company_id='',provider_id='')=>{
  await axios.post(`${base_url}section/xrun`,{
    company_id:company_id,
    provider_id:provider_id
  })
  .then((res)=>{
    setCompanyXRUNOBJ(res.data.data);
    let response=res.data.data;
    if(response.length > 0){
      let t=0;
      response.map((item)=>{
        t=t+parseInt(item.xrun_stock);
      })
      setCompanyXRUN(t);
    }else{
      setCompanyXRUN(0);
    }
  })
  .catch((err)=>{
    console.log(err);
  })
}


const COMPANY_XRUN_SET=async(values,callback=()=>{})=>{
  await axios.post(`${base_url}section/xrun_set`,values)
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
  })
  .catch((err)=>{
    console.log(err);
  })
}

const [companyXEMPTY,setCompanyXEMPTY]=useState(0);
const [companyXEMPTYOBJ,setCompanyXEMPTYOBJ]=useState([]);
const COMPANY_XEMPTY_API=async(company_id='',provider_id='')=>{
  await axios.post(`${base_url}section/xempty`,{
    company_id:company_id,
    provider_id:provider_id
  })
  .then((res)=>{
    setCompanyXEMPTYOBJ(res.data.data);
    let response=res.data.data;
    if(response.length > 0){
      let t=0;
      response.map((item)=>{
        t=t+parseInt(item.xempty_stock);
      })
      setCompanyXEMPTY(t);
    }else{
      setCompanyXEMPTY(0);
    }
  })
  .catch((err)=>{
    console.log(err);
  })
}


const COMPANY_XEMPTY_SET=async(values,callback=()=>{})=>{
  await axios.post(`${base_url}section/xempty_set`,values)
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
  })
  .catch((err)=>{
    console.log(err);
  })
}

const COMPANY_XRETURN_API=async(values,callback=()=>{})=>{
  await axios.post(`${base_url}section/xreturn`,values)
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
  })
  .catch((err)=>{
    console.log(err);
  })
}

const PROVIDER_XREFILL=async(values,callback=()=>{})=>{
  await axios.post(`${base_url}section/xrefill`,values)
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
  })
  .catch((err)=>{
    console.log(err);
  })
}


const [companyXPAY,setCompanyXPAY]=useState(0);
const [companyXPAYOBJ,setCompanyXPAYOBJ]=useState([]);
const COMPANY_XPAY_API=async(company_id='',provider_id='')=>{
  await axios.post(`${base_url}section/xpay`,{
    company_id:company_id,
    provider_id:provider_id
  })
  .then((res)=>{
    setCompanyXPAYOBJ(res.data.data);
    let response=res.data.data;
    if(response.length > 0){
      let t=0;
      response.map((item)=>{
        t=t+parseInt(item.xpay_stock);
      })
      setCompanyXPAY(t);
    }else{
      setCompanyXPAY(0);
    }
  })
  .catch((err)=>{
    console.log(err);
  })
}

const COMPANY_XPAID_API=async(values,callback=()=>{})=>{
  await axios.post(`${base_url}section/xpay_set`,values)
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
  })
  .catch((err)=>{
    console.log(err);
  })
}

const VEHICLE_EXPENSES=async(values,callback=()=>{})=>{
  await axios.post(`${base_url}vehicle/vexpense`,values)
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
  })
  .catch((err)=>{
    console.log(err);
  })
}

const [vehicleExpList,setVehicleExpList]=useState([]);
const VEHICLE_EXPENSES_LIST=async()=>{
  await axios.get(`${base_url}vehicle/vexpense_list`)
  .then((res)=>{
    setVehicleExpList(res.data.data);
  })
  .catch((err)=>{
    console.log(err);
  })
}

const VEHICLE_EXPENSE_DELETE=async(id,callback=()=>{})=>{
  await axios.post(`${base_url}vehicle/vexpense_delete`,{id:id})
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
  })
  .catch((err)=>{
    console.log(err);
  })
}

const EXPENSES=async(values,callback=()=>{})=>{
  await axios.post(`${base_url}vehicle/expenses`,values)
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
  })
  .catch((err)=>{
    console.log(err);
  })
}

const [ExpList,setExpList]=useState([]);
const EXPENSES_LIST=async()=>{
  await axios.get(`${base_url}vehicle/expenses_list`)
  .then((res)=>{
    setExpList(res.data.data);
  })
  .catch((err)=>{
    console.log(err);
  })
}

const EXPENSE_DELETE=async(id,callback=()=>{})=>{
  await axios.post(`${base_url}vehicle/expenses_delete`,{id:id})
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
  })
  .catch((err)=>{
    console.log(err);
  })
}


const CREATE_PLANT_API=async(data,callback=()=>{})=>{
  await axios.post(`${base_url}section/plant_add`,data)
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
  }).catch((err)=>{
    console.log(err);
  })
}

const [plants,setPlants]=useState([]);
const PLANT_LIST=async(callback=()=>{})=>{
  await axios.get(`${base_url}section/plants`)
  .then((res)=>{
    setPlants(res.data.data);
  })
  .catch((err)=>{
    console.log(err);
  })
}

const UPDATE_PLANT=async(values,callback=()=>{})=>{
  await axios.post(`${base_url}section/plant_update`,values)
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
  }).catch((err)=>{
    console.log(err);
  })
}

const DELETE_PLANT=async(id,callback=()=>{})=>{
  await axios.post(`${base_url}section/plant_delete`,{plant_id:id})
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
  }).catch((err)=>{
    console.log(err);
  })
}


const Provide_Tank_Vendor=async(values,callback=()=>{})=>{
  await axios.post(`${base_url}optimize/provide_tank_vendor`,values)
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
  }).catch((err)=>{
    console.log(err);
  })
}

const [vendor_status,setVendorStatus]=useState([]);
const Vendor_Status_API=async(callback=()=>{})=>{
  await axios.get(`${base_url}optimize/vendor_status`)
  .then((res)=>{
    setVendorStatus(res.data.data);
    callback();
  })
  .catch((err)=>{
    console.log(err);
  })
}


const Tank_Add_Run=async(values,callback=()=>{})=>{
  await axios.post(`${base_url}optimize/tank_add_run`,values)
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
  }).catch((err)=>{
    console.log(err);
  })
}

const Tank_Remove_Run=async(values,callback=()=>{})=>{
  await axios.post(`${base_url}optimize/tank_remove_run`,values)
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
  }).catch((err)=>{
    console.log(err);
  })
}

const Tank_Add_Empty=async(values,callback=()=>{})=>{
  await axios.post(`${base_url}optimize/tank_add_empty`,values)
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
  }).catch((err)=>{
    console.log(err);
  })
}

const Tank_Remove_Empty=async(values,callback=()=>{})=>{
  await axios.post(`${base_url}optimize/tank_remove_empty`,values)
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
  }).catch((err)=>{
    console.log(err);
  })
}

const Tank_ReturnForm_Empty=async(values,callback=()=>{})=>{
  await axios.post(`${base_url}optimize/tank_return`,values)
  .then((res)=>{
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: res.data.message,
    })
    callback();
  }).catch((err)=>{
    console.log(err);
  })
}


const [co2_transaction,setCo2Transaction]=useState([]);
const Co2_transactionAPI=async(callback=()=>{})=>{
  await axios.get(`${base_url}optimize/co2_transaction`)
  .then((res)=>{
    setCo2Transaction(res.data.data);
    callback();
  })
  .catch((err)=>{
    console.log(err);
  })
}

  return (
    <AppContext.Provider
      value={{
        user,
        islogged,
        signIn,
        GetuserDetails,
        userLastDayLog,
        signOut,
        islogin,
        addEmployeeAPI,
        empList,
        getEmployeeList,
        deleteEmployee,
        updateEmployee,
        controlCheckIN,
        empChecks,
        getEmployeeCheckList,
        convertSeconds,
        getAllEmployeeCheckList,
        empChecksALL,
        addMachine,
        machineList,
        getOnlyMachine,
        updateMachine,
        deleteMachine,
        addProject,
        projectList,
        getOnlyProject,
        updateProject,
        deleteProject,
        DateString,
        addDailyMachineStatus,
        mlog,
        getmachinelog,
        setMlog,
        addCO2Company,
        getCompany,
        companyList,
        updateCompanyDetails,
        deleteCompany,
        assignCO2,
        addVehicle,
        getVehicle,
        vehicleList,
        deleteVehicle,
        updateVehicle,
        getNotification,
        notificationList,
        addTechnicianAPI,
        getTechnicianList,
        techList,
        updateTechnician,
        deleteTechnican,
        addGatepass,
        gatepassList,
        getAllGatePass,
        deleteGatePass,
        getemployeelog,
        emplog,
        setEmplog,
        getProjectLog,
        projectLog,
        setProjectLog,
        getCo2Log,
        co2Log,
        setCo2Log,
        TRANSFER_MACHINE_API,
        TRANSFER_TECH_API,
        TRANSFER_EMP_API,
        CO2SETTING_API,
        CO2Setting,
        CO2STOCK_ADD_API,
        CO2STOCK_REMOVE_API,
        CO2EMPTY_ADD_API,
        CO2EMPTY_REMOVE_API,
        PROVIDER_ADD_API,
        PROVIDER_GET_API,
        providerList,
        PROVIDER_TANK_ADD,
        PROVIDER_STATUS_API,
        providerStatus,
        PROVIDER_STATUS_DELETE,
        COMPANY_ADD_TANK,
        COMPANY_TANK_STATUS,
        companyTankStatus,
        COMPANY_XRUN_API,
        companyXRUN,
        companyXRUNOBJ,
        COMPANY_XRUN_SET,
        COMPANY_XEMPTY_API,
        companyXEMPTY,
        companyXEMPTYOBJ,
        COMPANY_XEMPTY_SET,
        COMPANY_XRETURN_API,
        PROVIDER_XREFILL,
        COMPANY_XPAY_API,
        companyXPAY,
        COMPANY_XPAID_API,
        VEHICLE_EXPENSES,
        VEHICLE_EXPENSES_LIST,
        vehicleExpList,
        VEHICLE_EXPENSE_DELETE,
        EXPENSES,
        EXPENSES_LIST,
        ExpList,
        EXPENSE_DELETE,
        CREATE_PLANT_API,
        PLANT_LIST,
        plants,
        UPDATE_PLANT,
        DELETE_PLANT,
        Provide_Tank_Vendor,
        Vendor_Status_API,
        vendor_status,
        Tank_Add_Run,
        Tank_Remove_Run,
        Tank_Add_Empty,
        Tank_Remove_Empty,
        Tank_ReturnForm_Empty,
        Co2_transactionAPI,
        co2_transaction
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
