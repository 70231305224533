import React, {Suspense, useContext} from  'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { AppContext } from 'src/context/AppContext'

const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )

// Containers
const DefaultLayout = React.lazy(() => import('../layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('../views/pages/login/Login'))
const Register = React.lazy(() => import('../views/pages/register/Register'))
const Page404 = React.lazy(() => import('../views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('../views/pages/page500/Page500'))

const Main = () => {
  return (
    <HashRouter>
        <Suspense fallback={loading}>
    <Routes>
    <Route exact path="/login" name="Login Page" element={<Login context={AppContext} />} />
    {/* <Route exact path="/register" name="Register Page" element={<Register />} /> */}
    <Route exact path="/404" name="Page 404" element={<Page404 />} />
    <Route exact path="/500" name="Page 500" element={<Page500 />} />
    <Route path="*" name="Home" element={<DefaultLayout context={AppContext} />} />
    </Routes>
    </Suspense>
  </HashRouter>
  )
}

export default Main
